<template>
  <div class="RedpackerBankForm-wrapper">
    <form-panel ref="formPanel"
                v-bind="submitConfig"
                :form="form"
                :submitBefore="submitBefore">
      <col2-detail>
        <col2-block title="基础信息">
          <el-form-item label="停车场名称"
                        :rules="[{ required: true, message: '请输入停车场名称', trigger: 'blur' }]"
                        prop="parkingName">
            <v-input v-model="form.parkingName"
                     placeholder="请输入停车场名称"
                     :width="width" />
          </el-form-item>
          <el-form-item label="停车场配置类型"
                        :rules="[{ required: true, message: '请选择停车场配置类型', trigger: 'change' }]"
                        prop="configType">
            <v-select v-model="form.configType"
                      :options="configTypeOps"
                      :width="width"
                      :disabled="!!form.id"
                      @change="configTypeChange" />
          </el-form-item>
          <el-form-item label="停车场厂商名称"
                        :rules="[{ required: true, message: '请选择停车场厂商名称', trigger: 'change' }]"
                        prop="parkingConfigId">
            <v-select v-model="form.parkingConfigId"
                      :options="parkingConfigIdOps"
                      :width="width"
                      @change="parkingConfigIdChange" />
          </el-form-item>
          <el-form-item v-if="form.configType==2"
                        label="厂商停车场ID">
            <v-input v-model="form.manufacturerParkingId"
                     placeholder="请输入厂商停车场ID"
                     :maxlength="40"
                     :width="width" />
          </el-form-item>
          <el-form-item v-if="form.configType==2"
                        label="厂商停车场Code">
            <v-input v-model="form.parkingCode"
                     placeholder="请输入厂商停车场Code"
                     :maxlength="40"
                     :width="width" />
          </el-form-item>
          <el-form-item v-if="!form.id && form.configType==2"
                        label="关联项目-苑"
                        prop="communityIds"
                        :rules="[ { type: 'array', required: true,  message: '当前选项不允许为空', trigger: 'change' }  ]">
            <chosenListPanel key="communityIds"
                             :list.sync="form.communityIds"
                             @select="toSelectCommunityIds"></chosenListPanel>
          </el-form-item>
          <el-form-item v-if="form.configType==1"
                        label="停车场code"
                        :rules="[{ required: true, message: '请输入停车场code', trigger: 'blur' }]"
                        prop="parkingCode">
            <v-input v-model="form.parkingCode"
                     placeholder="请输入停车场code"
                     :width="width"
                     :disabled="!!form.id" />
          </el-form-item>
          <el-form-item v-if="!form.id && form.configType==1"
                        label="关联项目-苑"
                        :rules="[{ required: true, message: '请选择关联项目-苑', trigger: 'change' }]"
                        prop="communityId">
            <v-select2 class="my-select2"
                       placeholder="查询项目"
                       v-model="form.communityId"
                       v-bind="communityParams"
                       @onChange="getCommunityId"
                       :width="width"></v-select2>
            <v-select v-if="form.communityId && blockNameNameOps.length"
                      v-model="form.blockNameName"
                      :options="blockNameNameOps"
                      :width="width"
                      @change="blockNameNameChange" />
          </el-form-item>
          <el-form-item v-if="form.id"
                        label="关联项目-苑"
                        :rules="[{ required: true, message: '请选择关联项目-苑', trigger: 'change' }]"
                        prop="communityId">
            <span v-text="form.blockName ? `${form.communityName}-${form.blockName}` : form.communityName"></span>
          </el-form-item>
        </col2-block>
        <multi-select title="项目列表"
                      :isShow.sync="selectCommunityShow"
                      :searchUrl="getCommunityAndBlockInfo"
                      :headers="communitySelectTableHeader"
                      :searchParams="communitySearchParams"
                      :responseParams="communityResponseParams"
                      :responseKey="communityResponseKey"
                      :backFill.sync="form.communityIds"
                      @callback="communityIdsChange">
          <template #searchSlot>
            <v-input label="项目名称"
                     v-model="communitySearchParams.communityName"></v-input>
            <v-input label="苑名称"
                     v-model="communitySearchParams.blockName"></v-input>
            <v-input label="所在省"
                     v-model="communitySearchParams.province"></v-input>
          </template>
        </multi-select>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { queryUrl, updateUrl, submitCreateFormURL, getConfigListByConfigTypeUrl, getCommunityAndBlockInfo, getBlockNameByCommunityId, checkCommunityAndBlockIsBing } from './api'
import { configTypeOps, communityStatusMap } from './map'
import { Col2Detail, Col2Block, MultiSelect, ChosenListPanel } from 'components/bussiness'
import { communityParams } from 'common/select2Params'
// import { createHTMLVNode } from 'common/vdom'

export default {
  name: 'roadGateDeviceConfigForm',
  components: {
    Col2Detail,
    Col2Block,
    MultiSelect,
    ChosenListPanel
  },
  data () {
    return {
      getCommunityAndBlockInfo,
      selectCommunityShow: false,
      id: this.$route.query.id,
      width: 182,
      communityParams,
      configTypeOps: configTypeOps(),
      parkingConfigIdOps: [{
        text: '请选择',
        value: undefined
      }],
      singleCommunityItem: {},
      blockNameNameOps: [],
      checkCommunityText: '',
      form: {
        id: undefined,
        parkingName: '',
        configType: 2,
        parkingConfigId: undefined,
        communityIds: [],
        parkingCode: '',
        communityId: undefined,
        blockNameName: undefined,
        parkingId: '',
        manufacturerParkingId: ''
      },
      submitConfig: {
        // queryUrl: queryUrl,
        submitUrl: submitCreateFormURL,
        // submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
        submitMethod: 'POST'
      },
      communitySearchParams: {
        communityName: '',
        province: '',
        blockName: ''
      },
      communityResponseParams: {
        id: 'communityIdAndBlockName',
        name: 'communityNameAndBlockName'
      },
      communityResponseKey: {
        id: 'id',
        name: 'text'
      },
      communitySelectTableHeader: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'blockName',
          label: '苑名称'
        },
        {
          prop: 'communityStatus',
          label: '项目状态',
          formatter: (row) => {
            return communityStatusMap[row.communityStatus]
          }
        },
        {
          prop: 'communityStage',
          label: '项目阶段'
        },
        {
          prop: 'province',
          label: '所在省'
        },
        {
          prop: 'city',
          label: '所在市'
        },
        {
          prop: 'communityPhone',
          label: '项目电话'
        }
      ]
    }
  },
  computed: {
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$setBreadcrumb('编辑')
      this.pageType = 1
      this.getData({
        id
      })
    } else {
      this.$setBreadcrumb('新增')
    }
  },
  created () {
    this.getConfigListByConfigType(this.form.configType)
  },
  methods: {
    getData (params) {
      this.$axios.get(queryUrl, { params }).then(res => {
        if (res.status === 100) {
          let data = res.data
          this.form = Object.assign({}, data)
        }
      })
    },
    async communityIdsChange (list, ids) {
      this.checkCommunityText = ''
      let textArr = []
      let data = []
      if (list && list.length) {
        list.forEach(item => {
          data.push({
            communityIdAndBlockName: item.id,
            communityNameAndBlockName: item.text
          })
        })
      }
      let res = await this.$axios.post(checkCommunityAndBlockIsBing, {
        communityInfos: data
      })
      if (res.status === 100) {
        let backList = res.data
        backList.length && backList.forEach(it => {
          textArr.push(it.communityNameAndBlockName)
        })
      }
      if (textArr.length) {
        this.checkCommunityText = `以下项目-苑已存在关联的停车场信息${textArr.join('、')}`
        this.$alert(this.checkCommunityText)
      }
    },
    blockNameNameChange (data, item) {
      let checkList = []
      checkList.push({
        text: `${this.singleCommunityItem.name}-${item.text}`,
        id: `${this.singleCommunityItem.id}@${item.text}`
      })
      this.communityIdsChange(checkList)
    },
    getCommunityId (data, item) {
      this.getBlockNameList(data)
    },
    getBlockNameList (_data) {
      this.singleCommunityItem = _data || {}
      this.blockNameNameOps = []
      this.form.blockNameName = ''
      this.checkCommunityText = ''
      if (_data) {
        this.$axios.get(getBlockNameByCommunityId, {
          params: {
            communityId: _data.id
          }
        }).then(res => {
          if (res.status === 100) {
            let data = res.data || []
            let checkList = []
            let list = []
            if (data.length) {
              this.form.blockNameName = data[0]
              checkList.push({
                text: `${_data.name}-${data[0]}`,
                id: `${_data.id}@${data[0]}`
              })
              data.forEach((v, i, a) => {
                list.push({
                  text: v,
                  value: v
                })
              })
            } else {
              checkList.push({
                text: _data.name,
                id: _data.id
              })
            }
            this.communityIdsChange(checkList)
            this.blockNameNameOps = list
          }
        })
      }
    },
    toSelectCommunityIds () { this.selectCommunityShow = true },
    getConfigListByConfigType (configType) {
      if (!configType) {
        return false
      }
      this.$axios.get(getConfigListByConfigTypeUrl, {
        params: {
          configType
        }
      }).then(res => {
        if (res.status === 100) {
          let data = res.data || []
          let list = [
            {
              text: '请选择',
              value: undefined
            }
          ]
          data.forEach((v, i, a) => {
            list.push({
              text: v.manufacturerName,
              value: v.id
            })
          })
          this.parkingConfigIdOps = list
        }
      })
    },
    configTypeChange (data, item) {
      this.form.parkingCode = ''
      this.getConfigListByConfigType(data)
    },
    parkingConfigIdChange () { },
    async submitBefore (data) {
      let formData = {}
      if (data.id) {
        this.submitConfig.submitUrl = updateUrl
        formData = {
          id: data.id,
          parkingName: data.parkingName,
          parkingConfigId: data.parkingConfigId
        }
      } else {
        formData = {
          parkingName: data.parkingName,
          configType: data.configType,
          parkingConfigId: data.parkingConfigId
        }
        let ids = []
        if (data.configType === 2) {
          data.communityIds.forEach((item) => {
            ids.push({
              communityIdAndBlockName: item.id
            })
          })
          formData.parkingCode = undefined
        } else {
          ids.push({
            communityIdAndBlockName: data.blockNameName ? data.communityId + '@' + data.blockNameName : data.communityId
          })
          formData.parkingCode = data.parkingCode
        }
        formData.communityInfos = ids
      }
      if (data.configType === 2) {
        formData.manufacturerParkingId = data.manufacturerParkingId || ''
        formData.parkingCode = data.parkingCode || ''
      }
      if (formData.configType === 2) {
        this.checkCommunityText = ''
        let textArr = []
        let pdata = []
        data.communityIds.forEach(item => {
          pdata.push({
            communityIdAndBlockName: item.id,
            communityNameAndBlockName: item.text
          })
        })
        let res = await this.$axios.post(checkCommunityAndBlockIsBing, {
          communityInfos: pdata
        })
        if (res.status === 100) {
          let backList = res.data
          backList.length && backList.forEach(it => {
            textArr.push(it.communityNameAndBlockName)
          })
        }
        if (textArr.length) {
          this.checkCommunityText = `以下项目-苑已存在关联的停车场信息${textArr.join('、')}`
        }
      }
      if (this.checkCommunityText) {
        this.$alert(this.checkCommunityText)
        return false
      }
      return formData
    }
  }
}
</script>
<style lang="scss" scoped>
.my-select2 {
  margin-bottom: 20px;
}
</style>
