var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "RedpackerBankForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "停车场名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入停车场名称",
                            trigger: "blur",
                          },
                        ],
                        prop: "parkingName",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入停车场名称",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.parkingName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "parkingName", $$v)
                          },
                          expression: "form.parkingName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "停车场配置类型",
                        rules: [
                          {
                            required: true,
                            message: "请选择停车场配置类型",
                            trigger: "change",
                          },
                        ],
                        prop: "configType",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.configTypeOps,
                          width: _vm.width,
                          disabled: !!_vm.form.id,
                        },
                        on: { change: _vm.configTypeChange },
                        model: {
                          value: _vm.form.configType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "configType", $$v)
                          },
                          expression: "form.configType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "停车场厂商名称",
                        rules: [
                          {
                            required: true,
                            message: "请选择停车场厂商名称",
                            trigger: "change",
                          },
                        ],
                        prop: "parkingConfigId",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.parkingConfigIdOps,
                          width: _vm.width,
                        },
                        on: { change: _vm.parkingConfigIdChange },
                        model: {
                          value: _vm.form.parkingConfigId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "parkingConfigId", $$v)
                          },
                          expression: "form.parkingConfigId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.configType == 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "厂商停车场ID" } },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入厂商停车场ID",
                              maxlength: 40,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.manufacturerParkingId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "manufacturerParkingId", $$v)
                              },
                              expression: "form.manufacturerParkingId",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.configType == 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "厂商停车场Code" } },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入厂商停车场Code",
                              maxlength: 40,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.parkingCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "parkingCode", $$v)
                              },
                              expression: "form.parkingCode",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.form.id && _vm.form.configType == 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "关联项目-苑",
                            prop: "communityIds",
                            rules: [
                              {
                                type: "array",
                                required: true,
                                message: "当前选项不允许为空",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("chosenListPanel", {
                            key: "communityIds",
                            attrs: { list: _vm.form.communityIds },
                            on: {
                              "update:list": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "communityIds",
                                  $event
                                )
                              },
                              select: _vm.toSelectCommunityIds,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.configType == 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "停车场code",
                            rules: [
                              {
                                required: true,
                                message: "请输入停车场code",
                                trigger: "blur",
                              },
                            ],
                            prop: "parkingCode",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入停车场code",
                              width: _vm.width,
                              disabled: !!_vm.form.id,
                            },
                            model: {
                              value: _vm.form.parkingCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "parkingCode", $$v)
                              },
                              expression: "form.parkingCode",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.form.id && _vm.form.configType == 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "关联项目-苑",
                            rules: [
                              {
                                required: true,
                                message: "请选择关联项目-苑",
                                trigger: "change",
                              },
                            ],
                            prop: "communityId",
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                staticClass: "my-select2",
                                attrs: {
                                  placeholder: "查询项目",
                                  width: _vm.width,
                                },
                                on: { onChange: _vm.getCommunityId },
                                model: {
                                  value: _vm.form.communityId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "communityId", $$v)
                                  },
                                  expression: "form.communityId",
                                },
                              },
                              "v-select2",
                              _vm.communityParams,
                              false
                            )
                          ),
                          _vm.form.communityId && _vm.blockNameNameOps.length
                            ? _c("v-select", {
                                attrs: {
                                  options: _vm.blockNameNameOps,
                                  width: _vm.width,
                                },
                                on: { change: _vm.blockNameNameChange },
                                model: {
                                  value: _vm.form.blockNameName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "blockNameName", $$v)
                                  },
                                  expression: "form.blockNameName",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.id
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "关联项目-苑",
                            rules: [
                              {
                                required: true,
                                message: "请选择关联项目-苑",
                                trigger: "change",
                              },
                            ],
                            prop: "communityId",
                          },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.form.blockName
                                  ? `${_vm.form.communityName}-${_vm.form.blockName}`
                                  : _vm.form.communityName
                              ),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("multi-select", {
                attrs: {
                  title: "项目列表",
                  isShow: _vm.selectCommunityShow,
                  searchUrl: _vm.getCommunityAndBlockInfo,
                  headers: _vm.communitySelectTableHeader,
                  searchParams: _vm.communitySearchParams,
                  responseParams: _vm.communityResponseParams,
                  responseKey: _vm.communityResponseKey,
                  backFill: _vm.form.communityIds,
                },
                on: {
                  "update:isShow": function ($event) {
                    _vm.selectCommunityShow = $event
                  },
                  "update:is-show": function ($event) {
                    _vm.selectCommunityShow = $event
                  },
                  "update:backFill": function ($event) {
                    return _vm.$set(_vm.form, "communityIds", $event)
                  },
                  "update:back-fill": function ($event) {
                    return _vm.$set(_vm.form, "communityIds", $event)
                  },
                  callback: _vm.communityIdsChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "searchSlot",
                    fn: function () {
                      return [
                        _c("v-input", {
                          attrs: { label: "项目名称" },
                          model: {
                            value: _vm.communitySearchParams.communityName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "communityName",
                                $$v
                              )
                            },
                            expression: "communitySearchParams.communityName",
                          },
                        }),
                        _c("v-input", {
                          attrs: { label: "苑名称" },
                          model: {
                            value: _vm.communitySearchParams.blockName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "blockName",
                                $$v
                              )
                            },
                            expression: "communitySearchParams.blockName",
                          },
                        }),
                        _c("v-input", {
                          attrs: { label: "所在省" },
                          model: {
                            value: _vm.communitySearchParams.province,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.communitySearchParams,
                                "province",
                                $$v
                              )
                            },
                            expression: "communitySearchParams.province",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }