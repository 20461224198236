// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}communityParking/community/list`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}communityParking/community/export`
// 新增
const submitCreateFormURL = `${API_CONFIG.butlerBaseURL}communityParking/community/saveParkingList`
// 详情接口
const queryUrl = `${API_CONFIG.butlerBaseURL}communityParking/community/parkingInfo`
// 编辑详情接口
const updateUrl = `${API_CONFIG.butlerBaseURL}communityParking/community/updateParkingInfo`
// 根据停车场配置类型获取停车场厂商列表
const getConfigListByConfigTypeUrl = `${API_CONFIG.butlerBaseURL}communityParking/config/getConfigListByConfigType`
// 获取项目-苑多选
const getCommunityAndBlockInfo = `${API_CONFIG.butlerBaseURL}communityParking/community/getCommunityAndBlockInfo`
// 根据园区id，获取苑list
const getBlockNameByCommunityId = `${API_CONFIG.butlerBaseURL}communityParking/community/getBlockNameByCommunityId`
// 关闭或开启停车场厂商
const eidtStatus = `${API_CONFIG.butlerBaseURL}communityParking/community/eidtStatus`
// 检测项目-苑是否已存在关联的停车场信息
const checkCommunityAndBlockIsBing = `${API_CONFIG.butlerBaseURL}communityParking/community/checkCommunityAndBlockIsBing`
export {
  getListURL,
  exportListURL,
  queryUrl,
  updateUrl,
  submitCreateFormURL,
  getConfigListByConfigTypeUrl,
  getCommunityAndBlockInfo,
  getBlockNameByCommunityId,
  eidtStatus,
  checkCommunityAndBlockIsBing
}
